* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
}
.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar {
  width: 10px;
  height: 2px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--light-gray-clr);
}

.ant-drawer .ant-drawer-body {
  padding-top: 0 !important;
}
.green-row,
.green-row:hover {
  background-color: green !important;
}
.hidden {
  display: none;
}
.yellow-row,
.yellow-row:hover {
  background-color: lightyellow !important;
}
.gray-row,
.gray-row:hover {
  background-color: lightcyan !important;
}
.blue-row,
.blue-row:hover {
  background-color: #f4f6ff !important;
}
.ku-font {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}

.main-wrapper[lang='en'] {
  font-family: 'Noto Sans SC', sans-serif;
}
.main-wrapper[lang='ku'],
.main-wrapper[lang='ar'] {
  font-family: 'Rabar_014';
}

/* Dashboard styles */
.iconWrapper {
  top: -58px;
  position: relative;
  margin-bottom: -40px;
}
.bigIconContainer {
  width: 70px;
  height: 70px;
  margin: auto;
  color: #fff;
  position: relative;
  border-radius: 100px;
  box-shadow: rgb(221 228 231) 0px -5px 9px;
}

.bigIconContainer > .anticon {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customGutter {
  margin: 50px -7.5px 5px;
}

.customGutter > .ant-col {
  padding: 5px 7.5px;
}
.bold {
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .customGutter {
    margin: 50px -7.5px 27.5px;
  }

  .customGutter > .ant-col {
    padding: 27.5px 7.5px;
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: rgb(37, 34, 34);
}

.ant-tabs-tab {
  width: 20vh;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.transactionItemContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
}

.transactionViewContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
  grid-template-columns: 50px 20% 20% 150px 150px 1fr;
}

.transactionItemContainer > div,
.transactionViewContainer > div {
  padding: 15px 0;
  align-self: center;
}

.transactionContainer {
  margin-bottom: 20px;
}

.transactionInput {
  position: relative;
  padding: 4px 6px;
  border-radius: 3px;
  border: thin solid #d9d9d9;
}

.transactionInputHeader {
  padding: 0px 0 6px 6px;
}

.red-row,
.red-row:hover {
  background-color: #fff4f4 !important;
}

.unseen-row {
  position: relative;
  box-shadow: 0px 0px 12px -1px #76767652;
  -webkit-box-shadow: 0px 0px 12px -1px #76767652;
  -moz-box-shadow: 0px 0px 12px -1px #76767652;
}

.unseen-row::after {
  content: 'New';
  display: block;
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px 5px;
  background: #52c41a;
  color: #fff;
  border-radius: 14px;
  font-size: 12px;
}

.ant-checkbox-wrapper {
  font-size: 14px !important;
  padding: 1px !important;
}

.green-row,
.green-row:hover {
  background-color: #f4fff5 !important;
}

.dashboardCards {
  margin-top: 25px;
  height: 30vw;
  border: 3px solid #3d3d3d;
  border-radius: 0px 10px;
  text-align: center;
  width: 24%;
  margin-left: 10px;
}
.dashboardHeaders {
  font-size: 1.8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5vw;
  border-bottom: 2px dashed black;
}
.dashboardMenu {
  font-size: 1.3rem;
  background-color: white;
  transition: all;
  transition-duration: 0.5s;
}
.dashboardMenu:hover {
  color: white;
  font-size: 1rem;
  background-color: #4614a8;
}

.bt1:hover {
  border: 1px solid #10a5f5;
}
.bt2:hover {
  border: 1px solid red;
}
.bt3:hover {
  border: 1px solid #d4af37;
}
.btn_icon {
  color: #d4af37;
}

.bt4_hover:hover {
  border: 1px solid #259a7a;
}

.contract_action .ant-btn span {
  margin-right: 0;
}
/* //////////////////////////////////////// */

.avatar_menu_badge > sup {
  right: -10px;
}

.hover-effect:hover {
  color: #3c73a8;
}

.not-assgined {
  color: #ff0000;
}

.hover-effect,
.not-assgined {
  transition: all 0.2s ease;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #e17055 !important;
}

.ant-progress-success-bg {
  background-color: #1abc9c !important;
}

/* I added for display none of iframe */
body > iframe {
  display: none;
}

/* custom card for tabs */
.active-tab-order {
  position: relative;
  height: 150px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-tab-order.active {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #1677ff;
  padding-bottom: 5px;
}

.active-tab-order.active .mask {
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3) 86%);
}

.active-tab-order .mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgb(72 41 41 / 78%) 24%, rgb(65 48 48 / 83%) 100%);
  z-index: 1;
}

.active-tab-order h2 {
  color: white;
  font-size: 2rem;
  position: relative;
  z-index: 2;
}

.ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}
.active-tab-order img {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* .custom-scroll::-webkit-scrollbar {
  width: 10px;
}
  .custom-scroll::-webkit-scrollbar-thumb {
  background-color: #ff0004bd; 
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;

} */

.ant-menu-submenu-title {
  color: #fff !important;
}
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #1677ff !important;
}

.row-invoice-1 {
  background-color: #d1ffd1;
}

.row-invoice-0 {
  background-color: #ffd1d1;
}
